import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";
import Header from './Header'
import WashSiteDownloadSave from './WashSiteDownloadSave'
import Savings from "./Savings"
import WashSiteMap from "./WashSiteMap"
import Offers from "./Offers"
import SaveTime from "./SaveTime"
import Faqs from "./Faqs"
import Footer from "./Footer"
import './reset.scss'
import './Layout.scss'


const WashSiteLayout = ({ pageData }) => {
  
  // Set default country
  const [countryName, setCountryName] = useState("GB")
  // const [languageName, setLanguageName] = useState("EN")
  const [generalLng, setGeneralLng] = useState(0)
  const [generalLat, setGeneralLat] = useState(0)
  const [pageLoaded, setPageLoaded] = useState(false)

  const isBrowser = typeof window !== "undefined"

  // Fetch users country details -
  useEffect(() => {
    fetch('https://ipapi.co/json/')
    .then(function(response) {
      response.json().then(jsonData => {
        setCountryName(jsonData.country_code)
        setGeneralLng(jsonData.longitude)
        setGeneralLat(jsonData.latitude)
        // Override to test other countries
        //setCountryName("BE")
        // setInitialLanguage();
        setPageLoaded(true)
      });
    })
    .catch(function(error) {
      console.log(error)
      setCountryName(pageData.countryCode)
      // Fallback to London in case API is down - Will correct itself on user input
      setGeneralLng(-0.118092)
      setGeneralLat(51.509865)
      setPageLoaded(true)
    });
  }, [])

  return (
    <main className={`layout-active layout-` + pageData.countryCode + ` layout-language-` + pageData.languageCode}>
      <Helmet>
        <title>{pageData.downloadAndSaveTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        {pageData.metaDescription &&
          <meta name="description" content={pageData.metaDescription} />
        }
        {pageData.metaTitle &&
          <meta name="title" content={pageData.metaTitle} />
        }
        {pageData.metaKeywords &&
          <meta name="keywords" content={
            pageData.metaKeywords.map((keyword, i) => {
                if(i === 0) {
                  return `${keyword}`
                } else {
                  return ` ${keyword}`
                }

            }) 
          } />
        }
        {pageData.metaAuthor &&
          <meta name="author" content={pageData.metaAuthor} />
        }
        {/* {pageData.countryCode === "GB" &&
          <script src="https://forms.contacta.io/62cd710511da5d4c7b4cf6d9.js" type="text/javascript"></script>
        } */}
      </Helmet>
      <div className={`${pageLoaded ? "layout-active" : "layout-hidden"}`}>      
        <Header countryName={pageData.countryCode} headerContent={pageData} pageLoaded={pageLoaded} />
        {(pageData.countryCode === "BE" && isBrowser)  &&
          <div className="be-language">
            <span role="button" onKeyDown={() => window.location = '/be/fr'} onClick={() => window.location = '/be/fr'} tabIndex={0}>Français</span>
            <span role="button" onKeyDown={() => window.location = '/be/nl'} onClick={() => window.location = '/be/nl'} tabIndex={0}>Nederlands</span>
          </div>
        }
        <WashSiteDownloadSave downloadSaveContent={pageData} />
        {pageLoaded === true  &&
          <WashSiteMap mapContent={pageData} generalLng={generalLng} generalLat={generalLat} countryName={pageData.countryCode} />
        }
        {/* <Savings savingsContent={pageData} />
        <Offers offersContent={pageData} /> */}
        <SaveTime saveTimeContent={pageData} />
        {/* <Faqs faqsContent={pageData} /> */}
        <Footer footerContent={pageData} />
      </div>
    </main>
  )

}
export default WashSiteLayout
